<template>
  <!-- 展示用的上半边组件，逻辑复杂的人员列表组件在隔壁 -->
  <div class="payment">
    <Head :aircraftTicket="order" />
    <van-cell title="订单号" :value="order.orderSerialNo||order.orderNo" class="auto-cell" />
    <van-cell v-if="order.createTime" title="下单时间" :value="order.createTime" />
    <van-cell
      v-if="order.orderStatus"
      title="订单状态"
      :value="order.orderStatusText"
      :value-class="order.orderStatusText|btnType"
    />
    <a :href="'tel:'+phone">
      <van-cell v-show="phone">
        <template #title>
          客服
          <Logo :corp="order.corp" />
        </template>
        <template #default>
          <van-icon name="phone" class="van-cell__right-icon icon-btn blue" />
        </template>
      </van-cell>
    </a>

    <div class="gap"></div>
    <van-cell
      v-if="order.orderAmount"
      title="机票费用"
      :value="'￥'+(order.orderAmount)"
      value-class="red"
    />
    
    <van-cell
      v-if="insurancePrice*peoList.length"
      title="保险费用"
      :value="'￥'+(insurancePrice*peoList.length)"
      value-class="red"
    />

    <div class="gap"></div>

    <van-cell title="航班编号" :value="(order.airlineCompany||'')+order.flightNo" />
    <van-cell v-if="order.seatClass" title="出发时间" :value="order.departDate+' '+order.departTime" />

  </div>
</template>

<script>
import { getStatusColor } from "@/services/aircraft";
import Head from "./InfoHead";
import Logo from "@/components/Logo";
export default {
  components: { Head, Logo },
  computed: {
    // 单人保险价格
    insurancePrice() {
      if (!(0 in this.peoList) || !(0 in this.peoList[0].insurance)) return 0;
      let data = this.peoList[0].insurance;
      let price = 0;
      data.forEach((element) => {
        price += element.premium;
      });
      return price;
    },
  },
  props: [
    "order",
    "peoList",
    "isChange",
    "phone",
  ],
  filters: {
    // 按钮颜色
    btnType: function (data) {
      return getStatusColor(data);
    },
  },
};
</script>