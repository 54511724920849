import { render, staticRenderFns } from "./PaymentPeoList.vue?vue&type=template&id=0326adad&scoped=true&"
import script from "./PaymentPeoList.vue?vue&type=script&lang=js&"
export * from "./PaymentPeoList.vue?vue&type=script&lang=js&"
import style0 from "@/styles/insurance.css?vue&type=style&index=0&id=0326adad&scoped=true&lang=css&"
import style1 from "./PaymentPeoList.vue?vue&type=style&index=1&id=0326adad&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0326adad",
  null
  
)

export default component.exports